<template>

  <div>
    <el-form :inline="true" :model="query" class="query-form" size="mini">

      <el-form-item class="query-form-item" label="采集聊天">
        <el-select v-model="query.chat_id">
          <el-option label="全部" :value="0"></el-option>
          <el-option v-for="item in chatIdList" :label="item.title" :value="item.chat_id" :key="item.chat_id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="query-form-item" label="类型">
        <el-select v-model="query.type" placeholder="类型">
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="频道" :value="1"></el-option>
          <el-option label="群组" :value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="query-form-item" label="关键词">
        <el-input v-model="query.keyword" placeholder="关键词"></el-input>
      </el-form-item>

      <el-form-item class="query-form-item" label="内容">
        <el-input v-model="query.content" placeholder="内容"></el-input>
      </el-form-item>

      <el-form-item class="query-form-item" label="状态">
        <el-select v-model="query.status" placeholder="状态">
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
          <el-option label="正常" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button-group>
          <el-button type="primary" icon="el-icon-refresh" @click="onReset"></el-button>
          <el-button type="primary" icon="search" @click="onSubmit">查询</el-button>
          <el-button type="primary" @click.native="handleForm(null,null)">新增</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-scrollbar class="table-scrollbar" wrap-style="overflow-x: hidden;">
      <el-table
          v-loading="loading"
          :data="list"
          style="width: 100%;">
        <el-table-column
            width="50"
            label="ID"
            prop="id"
            fixed>
        </el-table-column>
        <el-table-column
            label="采集聊天">
          <template slot-scope="scope">
            <div>
              <div class="table-td-item">
                群ID：
                <span>{{scope.row.chat_id || '--'}}</span>
              </div>
              <div class="table-td-item">
                名称：
                <span>{{scope.row.chat_title || '--'}}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="70"
            label="类型">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type | typeFilterType">{{ scope.row.type | typeFilterName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            label="关键词"
            prop="keyword">
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            label="内容"
            prop="content">
        </el-table-column>
        <el-table-column
            width="100"
            label="数量"
            prop="num">
        </el-table-column>
        <el-table-column
            label="链接"
            prop="url">
          <template slot-scope="scope">
            <a :href="scope.row.url" target="_blank">{{scope.row.url}}</a>
          </template>
        </el-table-column>
        <el-table-column
            width="70"
            label="排序"
            prop="sort">
        </el-table-column>
        <el-table-column
            width="70"
            label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status | statusFilterType">{{ scope.row.status | statusFilterName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            width="150"
            label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time | parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            width="150"
            label="更新时间">
          <template slot-scope="scope">
            <span>{{ scope.row.modified_time | parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click.native="handleForm(scope.$index, scope.row)">编辑
            </el-button>
            <el-button type="text" size="small" @click.native="handleDel(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <el-pagination
        :page-size="query.limit"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total">
    </el-pagination>

    <!--表单-->
    <el-dialog
        :close-on-click-modal="false"
        :title="formMap[formName]"
        :visible.sync="formVisible"
        :before-close="hideForm"
        width="85%"
        top="5vh">
      <el-form :model="formData" :rules="formRules" ref="dataForm">

        <el-form-item label="聊天" prop="chat_id">
          <el-select v-model="formData.chat_id">
            <el-option :label="item.title" v-for="item in chatIdList" :value="item.chat_id" :key="item.chat_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键词" prop="keyword">
          <el-input v-model="formData.keyword" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input v-model="formData.content" type="textarea" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input v-model="formData.num" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="formData.url" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model.number="formData.sort" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="formData.status">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="hideForm">取消</el-button>
        <el-button type="primary" @click.native="formSubmit()" :loading="formLoading">提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {dataCollectionDelete, dataCollectionList, dataCollectionSave} from "../../api/group/dataCollection";
import {chatAll} from "../../api/group/chat";

const formJson = {
  id: "",
  chat_id: "",
  keyword: "",
  content: "",
  num: "",
  url: "",
  sort: 0,
  status: 1,
};
export default {
  data() {
    return {
      query: {
        chat_id: 0,
        type: -1,
        keyword: "",
        content: "",
        status: -1,
        page: 1,
        limit: 20,
      },
      list: [],
      total: 0,
      loading: true,
      index: null,
      formName: null,
      formMap: {
        add: "新增",
        edit: "编辑"
      },
      formLoading: false,
      formVisible: false,
      formData: formJson,
      formRules: {
        chat_id: [
          {required: true, message: "请选择聊天", trigger: "change"}
        ],
        keyword: [
          {required: true, message: "请输入关键词", trigger: "blur"}
        ],
        url: [
          {required: true, message: "请输入链接", trigger: "blur"}
        ],
        status: [
          {required: true, message: "请选择状态", trigger: "change"}
        ]
      },
      deleteLoading: false,
      chatIdList: [],
      chatIdMap: new Map(),
    };
  },
  methods: {
    onReset() {
      this.$router.push({
        path: ""
      });
      this.query = {
        chat_id: 0,
        type: -1,
        keyword: "",
        content: "",
        status: -1,
        page: 1,
        limit: 20,
      };
      this.getList();
      this.getChatList();
    },
    onSubmit() {
      this.$router.push({
        path: "",
        query: this.query
      });
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      dataCollectionList(this.query)
          .then(response => {
            this.loading = false;
            if (response.code) {
              this.$message.error(response.message);
            }
            this.list = response.data.list || [];
            this.total = response.data.total || 0;
          })
          .catch(() => {
            this.loading = false;
            this.list = [];
            this.total = 0;
          });
    },
    // 隐藏表单
    hideForm() {
      // 更改值
      this.formVisible = !this.formVisible;
      return true;
    },
    // 刷新表单
    resetForm() {
      if (this.$refs["dataForm"]) {
        // 清空验证信息表单
        this.$refs["dataForm"].clearValidate();
        // 刷新表单
        this.$refs["dataForm"].resetFields();
      }
    },
    // 显示表单
    handleForm(index, row) {
      this.formVisible = true;
      this.formData = JSON.parse(JSON.stringify(formJson));
      if (row !== null) {
        this.formData = Object.assign({}, row);
      }
      this.formName = "add";
      if (index !== null) {
        this.index = index;
        this.formName = "edit";
      }
    },
    formSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.formLoading = true;
          let data = Object.assign({}, this.formData);
          dataCollectionSave(data, this.formName).then(response => {
            this.formLoading = false;
            if (response.code) {
              this.$message.error(response.message);
              return false;
            }
            if (this.formName === "add") {
              // 向头部添加数据
              if (response.data && response.data.id) {
                data.id = response.data.id;
                data.create_time = new Date()
                data.modified_time = new Date()
                this.list.unshift(data);
              }
            } else {
              data.modified_time = new Date()
              this.list.splice(this.index, 1, data);
            }
            this.$message.success("操作成功");
            // 刷新表单
            this.resetForm();
            this.hideForm()
          });
        }
      });
    },
    // 删除
    handleDel(index, row) {
      if (row.id) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning"
        })
            .then(() => {
              let para = {id: row.id};
              this.deleteLoading = true;
              dataCollectionDelete(para)
                  .then(response => {
                    this.deleteLoading = false;
                    if (response.code) {
                      this.$message.error(response.message);
                      return false;
                    }
                    this.$message.success("删除成功");
                    // 刷新数据
                    this.list.splice(index, 1);
                  })
                  .catch(() => {
                    this.deleteLoading = false;
                  });
            })
            .catch(() => {
              this.$message.error("取消删除");
            });
      }
    },

    getChatList() {
      chatAll()
          .then(response => {
            this.loading = false;
            if (response.code) {
              this.$message.error(response.message);
              return
            }
            this.chatIdList = response.data.list || [];
            this.chatIdMap = new Map()
            for (let item of this.chatIdList) {
              this.chatIdMap.set(item.chat_id, item.title)
            }
          })
          .catch(() => {
          });
    },
  },
  filters: {
    statusFilterType(status) {
      const statusMap = {
        0: "danger",
        1: "success",
      };
      return statusMap[status];
    },
    statusFilterName(status) {
      const statusMap = {
        0: "禁用",
        1: "正常",
      };
      return statusMap[status];
    },
    typeFilterType(type) {
      const typeMap = {
        0: "danger",
        1: "success",
      };
      return typeMap[type];
    },
    typeFilterName(type) {
      const typeMap = {
        1: "频道",
        2: "群组",
      };
      return typeMap[type];
    },
  },
  mounted() {
  },
  created() {
    // 将参数拷贝进查询对象
    let query = this.$route.query;
    this.query = Object.assign(this.query, query);
    this.query.limit = parseInt(this.query.limit);
    // 加载表格数据
    this.getList();
    this.getChatList();
  }
};
</script>

<style lang="less" scoped>
</style>
