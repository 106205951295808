import axios from "../../utils/axios";

//
export function chatList(params) {
    return axios({
        url: "/admin/chat/list",
        method: "get",
        params: params
    });
}

//
export function chatAll() {
    return axios({
        url: "/admin/chat/all",
        method: "get"
    });
}

//
export function chatSave(data, formName, method = "post") {
    let url =
        formName === "add"
            ? "/admin/chat/add"
            : "/admin/chat/update";
    return axios({
        url: url,
        method: method,
        data: data
    });
}

//
export function chatDelete(data) {
    return axios({
        url: "/admin/chat/delete",
        method: "post",
        data: data
    });
}

//
export function chatListUpdate(data) {
    return axios({
        url: "/admin/chat/listUpdate",
        method: "post",
        data: data
    });
}
